<script>
import EventConfigForm from "@/views/menu/event/eventConfigForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    eventService: "eventService",
    toastService: "toastService"
  }
})
export default class EventConfig extends mixins(EventConfigForm) {
  formId = "config-event-form";

  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save
      }
    ];
  }

  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.eventService.saveConfig(this.model.eventId, this.model);
      this.toastService.success(this.translations.success.config_event_enable);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.events_config;
  }

  async created () {
    this.model = {
      googleAnalytics: {},
      linkedinTracking: {},
      facebookTracking: {},
      testEvent: {},
      zoomConfigs: {},
      onDemandLayout: {},
      agendaConfigs: {},
      liveSessionConfigs: {},
      backgroundConfigs: {},
      backgroundSocialwallConfigs: {},
      aiBotConfigs: {}
    };
    this.model = {
      ...this.model,
      ...await this.eventService.readConfig(this.$route.params.id)
    };
    this.model.eventId = this.$route.params.id;
    this.isReady = true;
  }
}
</script>
