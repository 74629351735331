<script>
import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
  },
})
export default class EventConfigForm extends mixins(EntityForm) {
  model = {};
  agendaLayoutList = [];

  get agendaLayouts() {
    return this.agendaLayoutList.map((x) => {
      return { value: x, label: x };
    });
  }

  async requestAgendaLayouts() {
    this.agendaLayoutList = await this.eventService.agendaLayout();
  }

  created() {
    this.requestAgendaLayouts();
  }

  get elements() {
    return [
      {
        type: "accordion",
        group: "object",
        columns: 2,
        resetColumns: true,
        panels: [
          {
            id: "testEvent",
            icon: "mdi-ladybug",
            header: this.translations.labels.config_event_form_testEvent,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels.config_event_form_enableTestEvent,
                type: "switch",
              },
              {
                id: "testEventTimeShift",
                label:
                  this.translations.labels.config_event_form_testEventTimeShift,
                type: "datetime",
              },
            ],
          },
          {
            id: "googleAnalytics",
            icon: "mdi-google-analytics",
            header: this.translations.labels.config_event_form_googleAnalytics,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_enableGoogleAnalytics,
                type: "switch",
              },
              {
                id: "trackingId",
                label:
                  this.translations.labels
                    .config_event_form_trackingIdGoogleAnalytics,
                type: "text",
              },
              //only for ganalytics isVisible:(item)=>{return !item.isSystem},
              {
                id: "pageTrackerScreenviewEnabled",
                label:
                  this.translations.labels
                    .config_event_form_pageTrackerScreenviewEnabledGoogleAnalytics,
                type: "switch",
              },
            ],
          },
          {
            id: "googleTagManager",
            icon: "mdi-poll",
            header: this.translations.labels.config_event_form_googleTagManager,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_enableGoogleTagManager,
                type: "switch",
              },
              {
                id: "trackingId",
                label:
                  this.translations.labels
                    .config_event_form_trackingIdGoogleAnalytics,
                type: "text",
              },
            ],
          },
          {
            id: "linkedinTracking",
            icon: "mdi-linkedin",
            header: this.translations.labels.config_event_form_linkedinTracking,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_enableLinkedinTracking,
                type: "switch",
              },
              {
                id: "trackingId",
                label:
                  this.translations.labels
                    .config_event_form_trackingIdLinkedinTracking,
                type: "text",
              },
            ],
          },
          {
            id: "facebookTracking",
            icon: "mdi-facebook",
            header: this.translations.labels.config_event_form_facebookTracking,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_enableFacebookTracking,
                type: "switch",
              },
              {
                id: "trackingId",
                label:
                  this.translations.labels
                    .config_event_form_trackingIdFacebookTracking,
                type: "text",
              },
            ],
          },
          {
            id: "zoomConfigs",
            icon: "mdi-video",
            header: this.translations.labels.config_event_form_zoomConfigs,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels.config_event_form_enableZoomConfigs,
                type: "switch",
              },
              {
                id: "apiKey",
                label:
                  this.translations.labels.config_event_form_zoomConfigsApiKey,
                type: "password",
              },
              {
                id: "apiSecret",
                label:
                  this.translations.labels
                    .config_event_form_zoomConfigsApiSecret,
                type: "password",
              },
            ],
          },
          {
            id: "onDemandLayout",
            icon: "mdi-ruler-square",
            header: this.translations.labels.config_event_form_onDemandLayout,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_enableOnDemandLayout,
                type: "switch",
              },
              {
                id: "layoutType",
                label: this.translations.labels.config_event_form_layoutType,
                type: "select",
                items: [
                  {
                    label: "Grid",
                    value: "Grid",
                  },
                  {
                    label: "Rows",
                    value: "Rows",
                  },
                  {
                    label: "Slideshow",
                    value: "Slideshow",
                  },
                ],
              },
            ],
          },
          {
            id: "agendaConfigs",
            icon: "mdi-view-agenda",
            header: this.translations.labels.config_event_form_agendaConfigs,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_enableAgendaConfigs,
                type: "switch",
              },
              {
                id: "showPopupCalendars",
                label:
                  this.translations.labels
                    .config_event_form_showPopupCalendarsAgendaConfigs,
                type: "switch",
              },
              {
                id: "filterByVisitorLevel",
                label:
                  this.translations.labels
                    .config_event_form_filterByVisitorLevelConfigs,
                type: "switch",
              },
              {
                id: "layout",
                label:
                  this.translations.labels
                    .config_event_form_agendaConfigsLayout,
                type: "select",
                items: this.agendaLayouts,
              },
            ],
          },
          {
            id: "backgroundConfigs",
            icon: "mdi-image-edit-outline",
            header: this.translations.labels.config_event_form_backgroundConfigs,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_enableBackgroundConfigs,
                type: "switch",
              },
              {
                id: "verticallyCenterScrollImage",
                label:
                  this.translations.labels
                    .config_event_form_verticallyCenterScrollImageBackgroundConfigs,
                hint:
                  this.translations.labels
                    .config_event_form_verticallyCenterScrollImageBackgroundConfigsHint,
                type: "switch",
              },
            ],
          },
          {
            id: "socialwallConfigs",
            icon: "mdi-newspaper-variant-outline",
            header: this.translations.labels.config_event_form_backgroundSocialwallConfigs,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_enableBackgroundSocialwallConfigs,
                type: "switch",
              },
              {
                id: "urlSocialwall",
                label:
                  this.translations.labels
                    .config_event_form_UrlSocialwallBackgroundSocialwallConfigs,
                hint:
                  this.translations.labels
                    .config_event_form_UrlSocialwallBackgroundSocialwallConfigsHint,
                type: "text",
              },
              {
                id: "backgroundImage",
                label:
                  this.translations.labels
                    .config_event_form_BackgroundColorSocialwallConfigs,
                hint:
                  this.translations.labels
                    .config_event_form_BackgroundColorSocialwallConfigsHint,
                type: "media",
                mediaType: "image",
              },
            ],
          },
          {
            id: "liveSessionConfigs",
            icon: "mdi-radiobox-marked",
            header: this.translations.labels.config_event_form_liveSessionConfigs,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_enableLiveSessionConfigs,
                type: "switch",
              },
              {
                id: "liveBeforeMinutes",
                label:
                  this.translations.labels
                    .config_event_form_liveSessionConfigsMinutesBefore,
                type: "number"
              },
            ],
          },
          {
            id: "aiBotConfigs",
            icon: "mdi-radiobox-marked",
            header: this.translations.labels.config_event_form_AIBotConfigs,
            elements: [
              {
                id: "isEnabled",
                label:
                  this.translations.labels
                    .config_event_form_AIBotEnabling,
                type: "switch",
              },
              {
                id: "src",
                label:
                  this.translations.labels
                    .config_event_form_AIBotSrc,
                type: "text"
              },
            ],
          }

        ],
      },
    ];
  }

  get helpLink() {
    return this.translations.pageHelp.event;
  }
}
</script>
